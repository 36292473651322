$(function(){
	initInput();
	$.views.settings.allowCode(true);
	$('.js-subscribe-open').on('click',function(){
		$('#reg-form-switcher').prop('checked',true)
	});

})

function initInput(){
	initInputChange();
	function initInputChange() {
		if ($('body').attr('data-init-init')) {
			return true;
		}
		$('body').attr('data-init-init',1);
		$('body').on('change', 'input[type="text"],input[type="tel"],input[type="email"]', function () {
			$(this).attr('value', $(this).val());
		});
	}

	$('.js-init-input').each(function(){
		var hasLabel = $(this).next()[0].tagName === 'LABEL',
			placeholder = $(this).attr('placeholder'),
			elementName = $(this).attr('name');

		if(hasLabel || !placeholder || !elementName) {
			return true;
		}
		var elementId = getId(this);

		$( '<label for="'+elementId+'">'+$(this).attr('placeholder')+'</label>' ).insertAfter( $(this) );
		$(this).attr('id',elementId).removeAttr('placeholder').addClass('init-input').val($(this).attr('value'));

		function getId(el){
			if($(el).attr('id')){
				return $(el).attr('id');
			}
			return getUniqueId($(el).attr('name'),1)

			function getUniqueId(name,counter){
				counter = counter || 1;
				if($('#'+name+'-'+counter).length){
					counter++;
					return getUniqueId(name,counter);
				}
				return name+'-'+counter;
			}
		}
	});
}